import React, {useState, useEffect} from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';
import Animation from "../components/animation/animation"
import Eugenio from "../../static/staff/eugenio_maucieri.webp"

const Container = styled.div`
   width: 100%;
   min-height: 100vh;
   position: relative;
`;

const TextWrapper = styled.div`
  width: 50%;
  float: left;
  padding: 0 5vw;
  @media (max-width: 767px) {
    width: 100vw;
    display: contents;
  }
  .portfolio-row {
    margin-bottom: 3rem;
    @media (max-width: 767px) {
      padding: 0 5vw;
    }
  }
  .date {
    display: block;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 0.5rem;
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }
  .text-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    margin-bottom: 0.5rem;
    align-items: baseline;
    @media (max-width: 767px) {
      width: unset;
      padding-bottom: 0.5rem;
    }
    span {
      color: ${({ theme }) => theme.primaryColor};
      padding-right: 0.5rem;
      font-weight: 400;
      font-style: italic;
    }
    p {
      margin: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  width: calc(50vw - 130px);
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  @media (max-width: 767px) {
    position: unset;
    width: 100%;
    text-align: center;
    height: unset;
  }
  img {
    width: 100%;
    @media (max-width: 767px) {
      height: auto;
      width: 90%;
      margin-bottom: 2rem;
    }
  }
`;

export default function Partecipazioni() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 960) setIsMobile(true)
  }, []);
  return (
    <Layout pageTitle="Partecipazioni - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000}>
        <h1>Partecipazioni</h1>
      </Fade>
      <Container>
        <ImageWrapper>
          <Animation isMobile={isMobile} position="top">
            <img src={Eugenio} alt="Dott. Eugenio Maucieri" />
          </Animation>
        </ImageWrapper>
        <TextWrapper>
        <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2006</span>
            <div className="text-container">
              <div>
                <span>Convegno </span>
              </div>
              <div>
                <p>La patologia delle mucose orali.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2007</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Restauro del dente trattato endodonticamente con composito e perni in fibra o fibra di vetro.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Tecniche di sbiancamento dentale.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Convegno </span>
              </div>
              <div>
                <p>Odontoiatria Restaurativa ed Endodonzia: stato dell’arte.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2008</span>
            <div className="text-container">
              <div>
                <span>Convegno </span>
              </div>
              <div>
                <p>Approccio odontoiatrico al paziente con malattie sistemiche.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Ortodonzia, gnatologia e posturologia annuale dott. Bisello.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>L’odontoiatria al servizio dell’ortodonzia e viceversa.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2009</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Ortodonzia, gnatologia e posturologia annuale dott. Bisello.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Il restauro diretto dei settori anteriori e posteriori.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Il trattamento ortodontico programmato: aspettative e risultati.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Endodonzia con il dott. Andrea Gesi su Mtwo, Lightspeed, System B + Obtura.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2010</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Gli anestetici locali: effetti collaterali, secondari, complicanze e loro trattamento.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Il bite nella pratica odontoiatrica: dall’applicazione alla finalizzazione.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Classe 2 e classe 3: diagnosi, piano di trattamento e meccaniche terapeutiche.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Bonding, rebonding, debonding.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2011</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Protesi fissa su denti naturali annuale dott. Prisco.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>In Office 3M di ortodonzia dott. Bisello.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2012</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Hybrid concept: come ottenere una sagomatura canalare rapida e sicura.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Straight-Wire ed estetica: lo stato dell’arte.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Congresso </span>
              </div>
              <div>
                <p>Influenza della preparazione dentale e del contorno coronale protesico sulla stabilità gengivale (tecnica B.O.P.T. originale).</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La sicurezza dei pazienti e degli operatori.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Audit clinico.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Spazzolini elettrici: tutto ciò che è necessario sapere per fornire raccomandazioni efficaci ai pazienti.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La terapia dell’alitosi. Utilizzo ed efficacia degli integratori alimentari e degli oli essenziali.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Appropriatezza delle cure.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La radiofrequenza in odontoiatria: estetica del sorriso e dei tessuti periorali del viso.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Convegno </span>
              </div>
              <div>
                <p>Pianificazione e realizzazione in implantologia.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Professione medica e responsabilità: l’indagine penale.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2013</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Attualità in farmacovigilanza.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Ipnosi medica rapida.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Congresso </span>
              </div>
              <div>
                <p>Sedazione cosciente nella pratica odontoiatrica: il protossido d’azoto.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Life coaching.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Chirurgia orale ambulatoriale.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2014</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Anatomia chirurgica su cadavere, chirurgia orale ed implantare.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2015</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Protesi Implantare.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La radioprotezione in odontoiatria: diagnostica avanzata e normativa di protezione da radiazioni.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2016</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La gestione delle emergenze, BLS-D/PBLS-D e disostruzione delle vie aeree.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Avanzato di Protesi teorico e pratico.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La ricostruzione dei tessuti duri e molli periimplantari per ottimizzare estetica, funzione e ridurre l'incidenza di complicanze.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Convegno </span>
              </div>
              <div>
                <p>L'allungamento della corona clinica.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Utilizzo Avanzato di Cone Beam 3D- Newtom Giano.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Carico Immediato.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La radioprotezione in odontoiatria: diagnostica avanzata e normativa di protezione da radiazioni. Ex D. Lgs 187/2000.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Restauri estetici diretti nei settori anteriori e posteriori.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La costruzione della protesi totale rimovibile.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2017</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Parodontologia clinica: la diagnosi della malattia parodontale e il controllo dell'infezione.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Trattamento avanzato delle malattie parodontali.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La soluzione implantare nel paziente parodontale.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2018</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Avanzato di gnatologia e protesi.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Terapia implantare.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2019</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La valutazione estetica e funzionale nel piano di trattamento della riabilitazione protesica.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Integrazione estetica, funzionale e biologica della riabilitazione protesica su denti.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La scelta del materiale ceramico dal restauro singolo alla riabilitazione completa.</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La gestione dei difetti ossei del mascellare superiore e inferiore.</p>
              </div>
            </div>
          </div>
          </Fade>
          <Fade left duration={1000}>
          <div className="portfolio-row">
            <span className="date">2020</span>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>Il piano di trattamento nei casi clinici complessi..</p>
              </div>
            </div>
            <div className="text-container">
              <div>
                <span>Corso </span>
              </div>
              <div>
                <p>La protesi adesiva: aspetti clinici, tecnici e merceologici.</p>
              </div>
            </div>
          </div>
          </Fade>
        </TextWrapper>
      </Container>
    </Layout>
  )
}