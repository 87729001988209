import React from "react"
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Animation = ({isMobile, position, delay, children}) => {

    if(isMobile){
      return(
        <Zoom>
          {children}
        </Zoom>
      )

    }else{
      switch (position) {
        case "top":
          return <Fade top delay={delay}>{children}</Fade>

        case "right":
          return <Fade right delay={delay}>{children}</Fade>

        case "bottom":
          return <Fade bottom delay={delay}>{children}</Fade>

        case "left":
          return <Fade left delay={delay}>{children}</Fade>

        default:
          return <Fade left delay={delay}>{children}</Fade>
      }
    }

}

export default Animation;